import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({
      hasAgreedToDataPolicy: false,
  }),
  actions: {
    agreedToDataPolicy() {
      console.log("agreedToDataPolicy user store updated");
      this.hasAgreedToDataPolicy = true;
    },
    clear() {
      this.hasAgreedToDataPolicy = false;
    },

  },
})