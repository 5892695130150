<template>
  <b-container fluid>
    <b-row align-v="top"  class="cards">
      <b-col  sm="12" md="4" lg="4" class="p-2" >
      
          <b-link class="text-dark equal-width" to="publications">
            <BCard title="Publications" bg-variant="white" border-variant="white" titleTag='h2' style="color: #4D833A !important;" >

              <font-awesome-icon icon="copy" size="5x"/>

              <p class="card-text">
                Publications that have interpreted AGAGE measurements to determine trace gas lifetimes and emissions.
              </p>

            </BCard>
          </b-link>

      </b-col>
      <b-col sm="12" md="4" lg="4" class="p-2" >

          <b-link class="text-dark equal-width" to="instruments">
            <b-card title="Instruments" bg-variant="white" border-variant="white" titleTag='h2' style="color: #4D833A !important;" >

              <font-awesome-icon icon="gauge-high" size="5x"/>

              <p class="card-text">
                Learn more about the instruments used in the AGAGE global network.
              </p>
            </b-card>
          </b-link>

      </b-col>
      <b-col  sm="12" md="4" lg="4" class="p-2" >

          <!-- <b-link class="text-dark equal-width" to="data"> -->
          <b-link class="text-dark equal-width" href="https://agage2.eas.gatech.edu/data_archive/"> 
            <b-card title="Data" bg-variant="white" border-variant="white" titleTag='h2' style="color: #4D833A !important;" >

              <font-awesome-icon icon="download" size="5x"/>

              <p class="card-text">
                Archive of the entire ALE/GAGE/AGAGE database, with all calibrated measurements from June 1978 to present (including pollution events).
              </p>
            </b-card>
          </b-link>

      </b-col>
    </b-row>
    <b-row align-v="center"  class="text-center cards">
      <b-col  sm="12" md="12" lg="12" >
        <hr class="mb-4">
        <h2>
        AGAGE is supported by a consortium of multinational 
        <b-link style="color: #4D833A" to="about/participating-institutions">institutions</b-link>
        and 
        <b-link style="color: #4D833A" to="about/participating-institutions">organizations</b-link>.
        </h2>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import { consts, store } from '@/init/init'

export default {

  name: 'InfoRow',

}
</script>

<style scoped>

h2.card-title {
  color: #4D833A  !important;  
  font-size: 1.4em;
}

h2 {
  color: black !important;
}

h2 a {
  color: #4D833A !important;
}

.cards {
  min-hight: 400px;
  word-break: keep-all;
}

.card-text {
  color: black;
}

.equal-width {
  width:25%;
}

.container {
  text-align: left;
}

.cards ::v-deep(a) {
  text-decoration-line: none;
}

.cards a {
  min-height:400px;
}

.cards ::v-deep(a:hover) {
  text-decoration-line: underline;
}


/* fontawesome icons */
.fa-download, .fa-upload, .fa-users, .fa-copy, .fa-gauge-high  {
  color: #4D833A;
  float: left;
  margin-right: 10px;
}


</style>
