<template>
  <b-container fluid>
    <b-row class="mt-1 mb-1" >
      <b-col  sm="12" md="2" lg="2" class="mt-1 mb-1">
        <BLink href="/missions/agage" class="logo" >
          <b-img :src="smalllogo.src" :alt="smalllogo.alt"  style="height: 60px" />
        </BLink>
      </b-col>
      <b-col  sm="12" md="10" lg="10" class="p-1">
        <BLink href="/missions/agage" class="logo" >
          {{smalllogo.alt}}
        </BLink>
        <br/>
        Sponsored by NASA's Atmospheric Composition Focus Area in Earth Science
      </b-col>
    </b-row>
    <b-row>
      <b-col type="dark" variant="custom" class="top-menu pt-0 mt-0 mb-2" style="background-color: #2F5779; color:white;">
       <b-navbar toggleable="lg" type="dark" class="ms-auto mb-2 mb-lg-0 pt-0 mt-0" variant="custom" right>
        
          <b-navbar-toggle target="nav-collapse" class="top-menu pt-0 mt-2 mb-0"  left></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav right>
            <b-navbar-nav class="ml-auto; pt-1 pb-1" type="dark" variant="custom" right>
              <!-- Right aligned nav items -->
              <template v-for="item in this.menu()">

                <b-nav-item v-if="!item.children" v-bind:key="item.displayName" :to="item.path" right>{{ item.displayName }}</b-nav-item>

                <b-nav-item-dropdown v-if="item.children" :text="item.displayName" style="color:white;">
                  <b-dropdown-item v-for="child_item in item.children" :href="item.path + '/' + child_item.path" style="color:white !important;" >{{child_item.displayName}}</b-dropdown-item>
                </b-nav-item-dropdown>


              </template> 

             </b-navbar-nav> 
          </b-collapse>
        </b-navbar>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import authMixins from '@/components/app/authMixins'
import { useUserStore } from '../../store/user-store.js'
import { storeToRefs } from 'pinia'

export default {

  name: 'AppHeader',

  mixins: [ authMixins ],

  data () {
    return {
    }
  },

}

</script>


<style>
a {
  color: #2F5779;
}
</style>

<style scoped>

/* requires use of ::v-deep() to reach "deep" into child component */
.logo {
  text-decoration: none;
  color: #2F5779;
  font-size: 1.5em;
}

.navbar{
  background-color: #2F5779;  
  color:white;
  padding-bottom: 0px;
}

.top-menu ::v-deep(a) > img { 
  margin-right: 0px;
  color:white;
}

.top-menu ::v-deep(a) { 
  background-color: #2F5779;  
  color:white;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.2em;
}

.top-menu ::v-deep(ul) {  
  background-color: #2F5779;  
  color:white;
}

.show {
  color: white !important;
  text-decoration-color: white !important;
}

.top-menu ::v-deep(a:hover) { 
  color:white;
}

.nav-item ::v-deep(a) {
  margin-right: 15px;
  color:white;
}

.navbar-nav {
  --bs-nav-link-padding-y: 0rem;
  color:white;
}

:deep(.btn-group) {
  margin-right: 10px;
  color:white;
}

:deep(.btn-group) > button {
  color: white ;
  font-weight: 500;
  font-size: 1.2em;
}

button.navbar-toggler {
  color: white;
  background-color: white;
}


</style>
